import React, { useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Moment from 'react-moment';
import moment from 'moment';
import 'moment/locale/id';
import { devicesActions } from './store';
import t from './common/localization';
import RemoveDialog from './RemoveDialog';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import SimCard from '@material-ui/icons/SimCard';
import Map from '@material-ui/icons/Map';
import NoSignal from '@material-ui/icons/SignalCellularNoSimSharp';
import Axios from 'axios';
import { Accordion } from '@material-ui/core'
import { AccordionSummary } from '@material-ui/core'
import { AccordionDetails } from '@material-ui/core'
Moment.globalLocale = 'id'
const url = window.location.href
const DeviceList = (props) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const devices = useSelector(state => Object.values(state.devices.items));
  // console.log(devices);
  const positions = props.positions;
  const dispatch = useDispatch();
  const history = useHistory();
  // useEffect(() => {
  //   // console.log('pos',positions)
  // }, ['positions'])

  // const handleMenuClick = (event) => {
  //   setMenuAnchorEl(event.currentTarget);
  // }

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  }

  const handleMenuEdit = () => {
    history.push('/device');
    handleMenuClose();
  }

  const handleMenuRemove = () => {
    setRemoveDialogOpen(true);
    handleMenuClose();
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

  const classes = useStyles();
  const showKec = (deviceId) => {
    if (positions[deviceId]) {
      if (positions[deviceId].speed === 0) {
        return positions[deviceId] ? Math.round(positions[deviceId].speed) + " km/h" : ''
      }
      return positions[deviceId] ? Math.round(positions[deviceId].speed) + " km/h" : ''
    }
  }

  const showArah = (deviceId) => {
    return positions[deviceId] ? positions[deviceId].course : ''
  }
  const showLong = (deviceId) => {

    return positions[deviceId] ? positions[deviceId].longitude : ''
  }
  const showLat = (deviceId) => {
    return positions[deviceId] ? positions[deviceId].latitude : ''
  }
  const showDevTime = (deviceId) => {
    return positions[deviceId] ? positions[deviceId].fixTime : ''
  }
  const showProtocol = (deviceId) => {
    return positions[deviceId] ? positions[deviceId].protocol : ''
  }

  const showDuration = (deviceId) => {
    return positions[deviceId] ? moment().diff(moment(positions[deviceId].fixTime), 'seconds') : ''
  }

  // Deklarasi variabel state baru yang kita sebut "count"
  const [count, setCount] = useState(0);
  const [alamat, setAlamat] = useState("Loading...");


  const handleClick = (deviceId) => {
    setAlamat("Loading ...")
    setCount(count + 1)//tambahan bs undefined device id
    if (positions[deviceId] !== undefined && positions[deviceId] !== undefined) {
      // console.log(positions[deviceId].latitude)
      // console.log(positions[deviceId].longitude)

      Axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
      Axios.get("https://revgeocode.search.hereapi.com/v1/revgeocode?at=" + positions[deviceId].latitude + "%2C" + positions[deviceId].longitude + "&lang=en-US&apiKey=cAKLnhF0TcZ770-9fDQTsj30Jz25kvC4lQfOAncwJGE")
        .then(res => {
          // console.log(res)
          // console.log(res.data.Response.View[0].Result[0].Location.Address.Label);
          setAlamat(res.data.items[0].address.label)
          //  return res.data.Response.View[0].Result[0].Location.Address.Label
        }
        )
    }
  }

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      {/* {console.log(devices,'testZZ')} */}
      {devices.map((device, index, list) => (
        <Accordion key={device.id} expanded={expanded === device.id} onChange={handleChange(device.id)} onClick={() =>
          handleClick(device.id)
        } >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>{device.name}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <Button variant="contained" color="primary" button key={device.id} onClick={() => {
                dispatch(devicesActions.select(device))
                props.onDeviceSelected && props.onDeviceSelected()
              }}>
                .. Lihat Lokasi ..
              </Button>
              {/* {device.status === "online" ? <SimCard color="primary" /> : <NoSignal color="secondary" />} SIM */}
              {/* <Moment>{showDevTime(device.id)}</Moment> */} <br />
              {/* {!showDuration(device.id) ? <img src={url + "/images/icon/offline.png"} height="20" widht="20"  alt="signal"/> : showDuration(device.id) <= 60 ? <img src={url + "/images/icon/4.png"} height="20" widht="20" alt="signal"/> : showDuration(device.id) <= 3600 ? <img src={url + "/images/icon/3.png"} height="20" widht="20" alt="signal"/> : showDuration(device.id) <= 86400 ? <img src={url + "/images/icon/2.png"} height="20" widht="20" alt="signal"/> : <img src={url + "/images/icon/1.png"} height="20" widht="20" alt="signal"/>} Signal GPS */}
              <br />
              <font size="2" >
                <table>
                  <tbody>
                    <tr>
                      <td valign="top">IMEI</td>
                      <td></td>

                      <td>{device.uniqueId}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td valign="top">No Telp</td>
                      <td></td>

                      <td>{device.phone}</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td valign="top">SIM/GPRS</td>
                      <td></td>
                      <td>{device.status === "online" ? <SimCard color="primary" /> : <NoSignal color="secondary" />}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td valign="top">Status</td>
                      <td></td>

                      <td>{!showDuration(device.id) ? <img src={url + "/images/icon/offline.png"} height="20" widht="20" alt="signal" /> : showDuration(device.id) <= 60 ? <img src={url + "/images/icon/4.png"} height="20" widht="20" alt="signal" /> : showDuration(device.id) <= 3600 ? <img src={url + "/images/icon/3.png"} height="20" widht="20" alt="signal" /> : showDuration(device.id) <= 86400 ? <img src={url + "/images/icon/2.png"} height="20" widht="20" alt="signal" /> : <img src={url + "/images/icon/1.png"} height="20" widht="20" alt="signal" />}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td valign="top">Waktu</td>
                      <td></td>

                      <td><Moment fromNow>{showDevTime(device.id)}</Moment></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td valign="top">Kecepatan</td>
                      <td></td>

                      <td>{showKec(device.id)}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td valign="top">Arah</td>
                      <td></td>

                      <td>{showArah(device.id)}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td valign="top">Lintang</td>
                      <td></td>

                      <td>{showLat(device.id)}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td valign="top">Bujur</td>
                      <td></td>

                      <td>{showLong(device.id)}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td valign="top">Protocol</td>
                      <td> </td>

                      <td>{showProtocol(device.id)}</td>
                      <td>.</td>
                    </tr>
                    <tr>
                      <td valign="top">Alamat</td>
                      <td> </td>

                      <td>{alamat}</td>
                      <td>.</td>
                    </tr>
                  </tbody>
                </table>
              </font>
              {/* <HistorySharpIcon />  <Moment fromNow>{showDevTime(device.id)}</Moment><br /> */}
              {/* <AlarmIcon /> <Moment format="DD-MM-YYYY HH:mm:ss">{showDevTime(device.id)}</Moment><br /> */}
              {/* ____{device.lastUpdate} */}
              {/* <Explore /> {showArah(device.id)}<br /> */}
              {/* <Speed /> {showKec(device.id)}<br /> */}
              {/* <Map />Latitude :{showLat(device.id)}<br />
              <Map />Longtitude :{showLong(device.id)}<br /> */}
              {/* <HomeIcon /> Alamat : <br /> */}
              {/* {alamat}<br /> */}
              <Button variant="contained" href={"http://maps.google.com/maps?f=q&q=" + showLat(device.id) + "," + showLong(device.id) + "&z=16"} startIcon={<Map />}>Klik Google Maps </Button><br />
            </Typography>
          </AccordionDetails>
          {index < list.length - 1 ? <Divider /> : null}
        </Accordion>
      ))
      }
      {/* <List>
        {devices.map((device, index, list) => (
          <Fragment key={device.id}>
            <ListItem button key={device.id} onClick={() => dispatch(devicesActions.select(device))}>
              <ListItemAvatar>
                <Avatar>
                  <LocationOnIcon /> {device.status}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={device.name} secondary={device.uniqueId} />
              <ListItemSecondaryAction>
                <IconButton onClick={handleMenuClick}>
                  <MoreVertIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            {index < list.length - 1 ? <Divider /> : null}
          </Fragment>
        ))
        }
      </List> */}
      <Menu id="device-menu" anchorEl={menuAnchorEl} keepMounted open={Boolean(menuAnchorEl)} onClose={handleMenuClose}>
        <MenuItem onClick={handleMenuEdit}>{t('sharedEdit')}</MenuItem>
        <MenuItem onClick={handleMenuRemove}>{t('sharedRemove')}</MenuItem>
      </Menu>
      <RemoveDialog open={removeDialogOpen} onClose={() => { setRemoveDialogOpen(false) }} />
    </>
  );
}

const mapStateToProps = state => {
  return {
    positions: state.positions.items
  }
};
export default connect(mapStateToProps)(DeviceList);
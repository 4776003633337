import React from 'react';

class LoginToken extends React.Component {
    
    componentDidMount() {
        fetch('/api/session', { method: 'POST', body: new URLSearchParams(`email=${this.props.match.params.email}&password=${this.props.match.params.password}`) }).then(response => {
            if (response.ok) {
                window.location="/"
            } else {
                alert("Username/Password salah")
            }
          });  
    }

    render = () => (
        <div className="container mt-2">
            <h1>Loading ...</h1>
        </div>
    );
}
export default LoginToken;

import 'mapbox-gl/dist/mapbox-gl.css';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet'
import Control from 'react-leaflet-control';
import Button from '@material-ui/core/Button';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import Moment from 'react-moment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
// import { geolocated } from "react-geolocated";
const url = window.location.href
const calculateMapCenter = (state) => {
  // console.log(state.devices.selectedId)
  if (state.devices.selectedId) {
    const position = state.positions.items[state.devices.selectedId] || null;
    if (position) {
      // console.log(position)
      return [position.latitude, position.longitude];
    }
  }
  return null;
}
const PositionWaktu = (state) => {
  // console.log(state.devices.selectedId)
  if (state.devices.selectedId) {
    const position = state.positions.items[state.devices.selectedId] || null;
    if (position) {
      // console.log(position)
      return position.fixTime;
    }
  }
  return null;
}
const PositionSpeed = (state) => {
  // console.log(state.devices.selectedId)
  if (state.devices.selectedId) {
    const position = state.positions.items[state.devices.selectedId] || null;
    if (position) {
      // console.log(position)
      return 'Kecepatan : ' + Math.round(position.speed) + ' km/h';
    }
  }
  return null;
}
const PositionCourse = (state) => {
  // console.log(state.devices.selectedId)
  if (state.devices.selectedId) {
    const position = state.positions.items[state.devices.selectedId] || null;
    if (position) {
      // console.log(position)
      return position.course;
    }
  }
  return null;
}
const PositionName = (state) => {
  const device = state.devices.items[state.devices.selectedId] || null;
  // console.log(device.name)
  if (state.devices.selectedId) {
    return 'Peta Mengikuti : ' + device.name
  }
  // {
  //   content: device ? device.name : ''
  // }
}
const PositionGSM = (state) => {
  const device = state.devices.items[state.devices.selectedId] || null;
  // console.log(device.name)
  if (state.devices.selectedId) {
    return 'GSM/Kartu : ' + device.status
  }
  // {
  //   content: device ? device.name : ''
  // }
}

const mapFeatureProperties = (state, position) => {
  const device = state.devices.items[position.deviceId] || null;
  // console.log(device.name)
  return device.name
  // {
  //   content: device ? device.name : ''
  // }
}

const mapFeaturePropertiess = (state, position) => {
  const device = state.devices.items[position.deviceId] || null;
  // console.log(device.name)
  return device.status
  // {
  //   content: device ? device.name : ''
  // }
}
const mystyle = {
  color: "white",
  backgroundColor: "DodgerBlue",
  padding: "0px",
  fontFamily: "Arial"
};
const iconPerson = new L.DivIcon({
  className: mystyle,
  html:
    '<img style=transform:rotate(284deg) src="https://cdn3.iconfinder.com/data/icons/faticons/32/arrow-right-01-512.png" width="20" height="50" />'
    + '<span class=my-div-span>Loading...</span>'

});
// parsing data dari ws
const mapStateToProps = state => ({
  PositionGSM: PositionGSM(state),
  PositionWaktu: PositionWaktu(state),
  PositionName: PositionName(state),
  PositionCourse: PositionCourse(state),
  PositionSpeed: PositionSpeed(state),
  mapCenter: calculateMapCenter(state),
  data: {
    markers: Object.values(state.positions.items).map(position =>
    (
      {
        key: position.id,
        position: [position.latitude, position.longitude],
        content: mapFeatureProperties(state, position),
        icon: new L.DivIcon({
          className: 'mystyle',
          html:
            '<img style=transform:rotate(' + position.course + 'deg)' + ' src=' + url + 'images/icon/' + position.attributes.motion + "_" + mapFeaturePropertiess(state, position) + '.svg height=25 width=25/>'
            + '<h3 style=" text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white; color:black">' + mapFeatureProperties(state, position) + '</h3>'
        }
        )
      }))
  }
});
const MyPopupMarker = ({ content, icon, position }) => (
  <Marker position={position} icon={icon}>
    <Popup>{content}</Popup>
  </Marker>
)
const MyMarkersList = ({ markers }) => {
  const items = markers.map(({ key, ...props }) => (
    <MyPopupMarker key={key} {...props} />
  ))
  return <Fragment>{items}</Fragment>
}
class MainMap extends Component {

  // pas update data
  constructor(props) {
    super(props);
    this.state = {
      center: [-6.820762, 107.142960],
      lat: -6.820762,
      lng: 107.142960,
      zoom: 7,
      markers: [
        {
          key: 'Loading',
          position: [-6.820762, 107.142960],
          content: 'Loading',
          icon: iconPerson
        },
      ],
    };
  }

  componentDidMount() {
    this.timerID = setInterval(
      () => this.tick(),
      0
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.mapCenter !== this.props.mapCenter) {
      this.setState({
        center: this.props.mapCenter,
        zoom: 19,
      });

    }
  }
  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick() {
    this.setState({
      markers: this.props.data.markers
    });
  }

  handleClick() {
    // console.log("click me")
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(getPosition);
    }
    function getPosition(position) {
      // console.log(position.coords.latitude, position.coords.longitude);
      if (position.coords.latitude !== undefined && position.coords.longitude !== undefined) {

      }
    }
  }
  render() {
    const style = {
      position: 'absolute',
      width: '100%',
      height: '100%',
    };
    return (
      <div style={style}>
        <Map
          center={this.state.center}
          zoom={this.state.zoom}
          style={style}
        >
          <TileLayer
            // attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}&s=Ga"
          />
          <MyMarkersList markers={this.state.markers} />
          <Control position="topleft" >
            <Button size="small" variant="contained"
              color="primary" onClick={() => this.props.setDrawer(!this.props.stateDrawer)}><DoubleArrowIcon />
            </Button>

          </Control>
          <Control position="topright" >
            <Table style={{ backgroundColor: "white" }}>
              <TableBody>
                <tr>
                  <th>{this.props.PositionName}</th>
                </tr>
                <tr>
                  <td><Moment fromNow>{this.props.PositionWaktu}</Moment></td>

                </tr>
                <tr>
                  <td>{this.props.PositionSpeed}</td>
                </tr>
              </TableBody>
            </Table>
          </Control>
          {/* <Control position="bottomright" >
       <button onclick={this.handleClick()}>Posisi Saya</button>
     </Control> */}
        </Map>
      </div>
    )
  }
}

export default connect(mapStateToProps)(MainMap);
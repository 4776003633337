import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, withWidth } from '@material-ui/core';
import ContainerDimensions from 'react-container-dimensions';
import queryString from 'query-string'

// import DeviceList from './DeviceList';
// import UsersList from './UsersList';
// import Alamat from './Alamat'

import MainMap from './MainMap';
import MainToobar from './MainToolbar';
import SocketController from './SocketController';


const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh",
    display: "flex",
    flexDirection: "column"
  },
  content: {
    flexGrow: 1,
    overflow: "hidden",
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down('xs')]: {
      flexDirection: "column-reverse"
    }
  },
  drawerPaper: {
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      width: 350
    },
    [theme.breakpoints.down('xs')]: {
      height: 250
    }
  },
  mapContainer: {
    flexGrow: 1
  }
}));


const MainPage = ({ width }) => {
  
  const [loading, setLoading] = useState(true)
  const [drawer, setDrawer] = useState(false)
  const classes = useStyles();
  const history = useHistory();

  // console.log("page", props.stateDrawer);

  useEffect(() => {
    const values = queryString.parse(window.location.search)
    // console.log(values.u) 
    // console.log(values.p)
    
    fetch('/api/session', { method: 'POST', body: new URLSearchParams(`email=${values.u}&password=${values.p}`) }).then(response => {
      if (response.ok) {
        window.location = '/'; // TODO: Avoid calling sessions twice
      } else {
        fetch('/api/session').then(response => {
          if (response.ok) {
            setLoading(false);
          } else {
            // history.push('/login');
            window.location.assign("https://whyen-tracker.com")
          }
        });
      }
    });

   
  }, [history]);

  // console.log("state drawer", drawer);

  return loading ? (<div>
    <h1 align="center">Loading...</h1>
  </div>) : (
      <div className={classes.root}>
        <SocketController />
        <MainToobar stateDrawer={drawer} setDrawer={setDrawer} />
        <div className={classes.content}>
          {/* <Drawer
            anchor={isWidthUp('sm ', width) ? "left" : "bottom"}
            variant="permanent"
            classes={{ paper: classes.drawerPaper }}>
            <DeviceList />
            <UsersList />
            <Alamat />
          </Drawer> */}
          <div className={classes.mapContainer}>
            <ContainerDimensions>

              <MainMap stateDrawer={drawer} setDrawer={setDrawer} />
            </ContainerDimensions>
          </div>
        </div>
      </div>
    );
}

export default withWidth()(MainPage);

import React from 'react';
import { Switch, Route } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline';
import MainPage from './MainPage';
import LoginPage from './LoginPage';
import RouteReportPage from './RouteReportPage';
import DevicePage from './DevicePage';
import UsersList from './UsersList';
import LoginToken from './LoginToken';

import createActivityDetector from "activity-detector";
import {isMobile} from 'react-device-detect';

function useIdle(options) {
  const [isIdle, setIsIdle] = React.useState(false);
  React.useEffect(() => {
    const activityDetector = createActivityDetector(options);
    activityDetector.on("active", () => setIsIdle(false));
    activityDetector.on("idle", () => setIsIdle(true));
 

    // clean the subscription
    return () => {
      activityDetector.stop();
      setIsIdle(false)
    };
  });
  return isIdle;
}

const App = () => {
  const isIdle = useIdle({ timeToIdle: 600000 });
  // console.log(isIdle)
  if (isMobile) {
    return <div> 
        {/* {!isIdle ? '' :  window.location="/"} */}
        <CssBaseline />
      <Switch>
        <Route exact path='/' component={MainPage} />
        <Route exact path='/login/' component={LoginPage} />
        <Route exact path='/device' component={DevicePage} />
        <Route exact path='/users' component={UsersList} />
        <Route exact path='/reports/route' component={RouteReportPage} />
        <Route path='/logintoken/:email/:password' component={LoginToken} />
      </Switch>
    </div>
 }
  
  return (
    <>
      <CssBaseline />
      <Switch>
        <Route exact path='/' component={MainPage} />
        <Route exact path='/login/' component={LoginPage} />
        <Route exact path='/device' component={DevicePage} />
        <Route exact path='/users' component={UsersList} />
        <Route exact path='/reports/route' component={RouteReportPage} />
        <Route path='/logintoken/:email/:password' component={LoginToken} />
      </Switch>
    </>
  );
}

export default App;

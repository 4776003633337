import React from 'react';
// import { useSelector } from 'react-redux';
// import { useHistory } from 'react-router-dom';

const UserList = (props) => {
    // const users = useSelector(state => Object.values(state.users.items));
    // const history = useHistory();
  
    return (
    <div>
        {/* {JSON.stringify(users)} */}
    </div>  
    )
}
  
  export default UserList;